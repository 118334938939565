import request from '@/utils/request.js'
// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'POST',
    url: '/api/user/index'
  })
}
// 用户反馈
export const sendFanKui = content => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.user/feedback',
    data: {
      content
    }
  })
}
