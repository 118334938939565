import request from '@/utils/request.js'
// 获取考试题目
export const getTestQuestions = id => {
  return request({
    method: 'POST',
    url: '/api/exams/getquestion',
    data: {
      user_plan_id: id
    }
  })
}
// 提交考试
export const postMyTest = data => {
  return request({
    method: 'POST',
    url: '/api/exams/score',
    data
  })
}
