import request from '@/utils/request.js'
// 获取会员列表
export const getVipList = () => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.user/vip_card'
  })
}
// 购买会员卡
export const buyVip = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.user/buy_vip',
    data: {
      id
    }
  })
}
