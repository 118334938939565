<template>
  <div class="certificate-info">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="证书详情" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->
    <div class="wrap">
      <img :src="certificateInfo.image" alt="" />
      <div class="bottom">
        <van-button class="dowm" type="primary">我要下载</van-button>
        <van-button class="email" type="primary" @click="$router.push('/CertificateMail/' + id)" v-if="certificateInfo.pay_status !== 1">我要邮寄</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getCertificateInfo } from '@/api/Mine/CertificateInfo'
export default {
  name: 'CertificateInfo',
  props: ['id'],
  data() {
    return {
      // 证书信息
      certificateInfo: {}
    }
  },
  mounted() {
    // 获取证书详情
    this.getCertificateInfo()
  },
  methods: {
    // 获取证书详情
    async getCertificateInfo() {
      const { data: res } = await getCertificateInfo(this.id)
      this.certificateInfo = res
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 708px;
  margin: 0 auto;
  img {
    width: 708px;
    height: 935px;
  }
  .bottom {
    margin-top: 59px;
    display: flex;
    justify-content: space-around;
    .dowm {
      width: 220px;
      height: 60px;
      border: 1px solid #a6a6a6;
      border-radius: 10px;
      font-size: 26px;
      font-weight: normal;
      color: #333333;
      background-color: #fff;
    }
    .email {
      width: 220px;
      height: 60px;
      background: #fed30b;
      border-radius: 10px;
      font-size: 26px;
      font-weight: normal;
      border: 0;
      color: #ffffff;
    }
  }
}
</style>
