import request from '@/utils/request.js'
// 获取考试列表
export const getMyTestList = data => {
  return request({
    method: 'POST',
    url: '/api/exams/exam',
    data: {
      status: data.status,
      page: data.page,
      limit: data.limit
    }
  })
}
