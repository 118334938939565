<template>
  <div class="mine">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="我的" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <div class="wrap">
      <!-- 头部区域 -->
      <div class="header">
        <div class="header_left">
          <van-image class="avatar" round :src="userInfo.avatar" />
        </div>
        <div class="header_right">
          <div class="row">
            <div class="name" v-text="userInfo.nickname"></div>
            <img class="level_icon" v-if="userInfo.isvip === 1" src="../../assets/images/level_icon.png" alt="" />
            <div class="level" v-if="userInfo.isvip === 1">{{ userInfo.vipname }}</div>
            <div class="time" v-if="userInfo.isvip === 1">到期时间:{{ userInfo.vip_expiretime }}</div>
          </div>
          <div class="row">
            <div class="number">学号:{{ userInfo.userno }}</div>
            <div class="time">总时长:{{ userInfo.studytimes }}</div>
          </div>
        </div>
      </div>
      <!-- 头部区域 -->

      <!-- 开通会员区域 -->
      <open-vip></open-vip>
      <!-- 开通会员区域 -->

      <!-- 底部导航区域 -->
      <div class="card_box">
        <router-link :to="item.url" class="item" v-for="(item, index) in bottomBar" :key="index">
          <img :src="item.icon" alt="" />
          <div class="text" v-text="item.text"></div>
        </router-link>
        <a :href="'tel:+' + phone" class="item">
          <img src="../../assets/images/service.png" alt="" />
          <div class="text">联系客服</div>
        </a>
        <router-link to="/Setting" class="item">
          <img src="../../assets/images/setting.png" alt="" />
          <div class="text">设置</div>
        </router-link>
<!--        <router-link to="/BuyVip" class="item">
          <img src="../../assets/images/top_up.png" alt="" />
          <div class="text">购买会员</div>
        </router-link>
        <a tag='a' :href="kaoshiurl" class="item">
          <img src="../../assets/images/study.png" alt="" />
          <div class="text">去学习</div>
        </a> -->
        <a class="item" @click="show = true">
          <img src="../../assets/images/exit.png" alt="" />
          <div class="text">反馈</div>
        </a>
      </div>
      <!-- 底部导航区域 -->

      <van-dialog v-model="show" title="反馈" show-cancel-button @close="message = ''" @confirm="sendFanKui">
        <van-field v-model="message" rows="4" type="textarea" placeholder="请输入留言" />
      </van-dialog>
    </div>
  </div>
</template>
<script>
import { getUserInfo, sendFanKui } from '@/api/Mine/Mine'
import OpenVip from '../../components/open-vip'
export default {
  name: 'Mine',
  components: { OpenVip },
  data() {
    return {
      // 用户信息
      userInfo: {},
      // 客服电话
      phone: null,
      // 反馈信息
      message: '',
      // 显示反馈
      show: false,
      // 考试地址
      kaoshiurl: '',
      // 底部导航
      bottomBar2: [
        { text: '我的收藏', icon: require('../../assets/images/like.png'), url: '/MyCollection' },
        { text: '已购课程', icon: require('../../assets/images/delivery.png'), url: '/AlreadyBoughtCourse' },
        { text: '获得证书', icon: require('../../assets/images/book.png'), url: '/CertificateList' },
        { text: '我的考试', icon: require('../../assets/images/edit.png'), url: '/MyTest' }
      ],
      bottomBar: [
        { text: '获得证书', icon: require('../../assets/images/book.png'), url: '/CertificateList' },
        { text: '我的考试', icon: require('../../assets/images/edit.png'), url: '/MyTest' }
      ]
    }
  },
  mounted() {
    // 获取用户信息
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      const { data } = await getUserInfo()
      this.userInfo = data.userinfo
      this.kaoshiurl = 'http://employee.zfbhelper.com/index/index/?token=' + data.userinfo.token
      this.phone = data.huiyuandianhua
    },
    // 反馈
    async sendFanKui() {
      if (this.message.length <= 0) {
        return this.$toast('留言不能为空')
      }
      const res = await sendFanKui(this.message)
      if (res.code === 1) {
        this.$toast.success('提交成功')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 710px;
  margin: 0 auto;
  // 头部
  .header {
    padding-top: 10px;
    display: flex;
    margin-bottom: 36px;
    .header_left {
      width: 93px;
      height: 93px;
      .avatar {
        width: 100%;
        height: 100%;
      }
    }
    .header_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      justify-content: space-around;
      .name {
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
      .row {
        display: flex;
        height: 30px;
        align-items: center;
        .number {
          font-size: 22px;
          font-weight: 400;
          color: #666666;
        }
        .time {
          margin-left: auto;
          font-size: 22px;
          font-weight: 400;
          color: #666666;
        }
        .level_icon {
          width: 27px;
          height: 30px;
          margin-left: 50px;
        }
        .level {
          font-size: 22px;
          font-weight: 400;
          margin-right: 10px;
          color: #fed30b;
        }
      }
    }
  }

  // 底部导航
  .card_box {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    .item {
      margin: 25px 0;
      display: block;
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: 38px;
      }
      .text {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
