<template>
  <div class="test-questions">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
      <template slot="title">
        <div>
          <span>倒计时：</span>
          <span class="time">{{ lefttime | formatDate }}</span>
        </div>
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <!-- 答题区域 -->
    <div class="content-box">
      <question-item v-show="questionIndex === index" v-for="(item, index) in questionList" :key="item.id" :index="index" :questionInfo="item" @on-change="onChange"></question-item>
    </div>
    <!-- 答题区域 -->

    <!-- 按钮区域 -->
    <div class="bottom-button">
      <van-button class="btn" @click="switchTopic('on')">上一题</van-button>
      <van-button v-show="questionIndex < questionLength - 1" class="btn" @click="switchTopic('under')">下一题</van-button>
      <van-button v-show="questionIndex === questionLength - 1" class="btn" @click="submit">提交</van-button>
    </div>
    <!-- 按钮区域 -->
  </div>
</template>
<script>
import { getTestQuestions, postMyTest } from '@/api/Mine/TestQuestions'
import questionItem from './components/question-item.vue'
export default {
  name: 'TestQuestions',
  components: { questionItem },
  props: {
    // 考试计划ID
    Id: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      // 控制题目显示的索引
      questionIndex: 0,
      // 考试倒计时
      lefttime: 0,
      // 考试总时长
      time: null,
      // 考试题目
      questionList: [],
      // 题目数量
      questionLength: 0,
      // 考试题目答案
      questionForm: {
        1: [],
        2: [],
        3: []
      },
      // 考试题目ID
      user_exams_id: null
    }
  },
  mounted() {
    // 获取考试题目
    this.getTestQuestions()
  },
  methods: {
    // 获取考试题目
    async getTestQuestions() {
      const res = await getTestQuestions(this.Id)
      if (res.code === 0) {
        this.$router.push('/MyTest')
        return this.$toast.fail(res.msg)
      }
      this.lefttime = res.data.msg.hours * 60
      this.time = res.data.msg.hours * 60
      // 倒计时
      this.timeOut()
      this.questionList = res.data.questions
      this.questionLength = this.questionList.length
      this.user_exams_id = res.data.msg.user_exams_id
    },
    // 倒计时
    timeOut() {
      const timeInterval = setInterval(() => {
        this.lefttime = this.lefttime - 1
        if (this.lefttime <= 0) {
          this.submit()
          this.$toast('时间到')
          clearInterval(timeInterval)
        }
      }, 1000)
    },
    // 切换题目
    switchTopic(value) {
      if (value === 'on') {
        if (this.questionIndex === 0) {
          this.$toast('当前题目为第一题')
        } else {
          this.questionIndex -= 1
        }
      }
      if (value === 'under') {
        this.questionIndex += 1
      }
    },
    // 修改答案
    onChange(value) {
      const obj = {
        id: value.id,
        answer: value.answer
      }
      // 单选
      if (value.type === '1') {
        // 判断是否存在当前题目
        const index = this.questionForm[1].findIndex(item => item.id === obj.id)
        if (index === -1) {
          this.questionForm[1].push(obj)
        } else {
          this.questionForm[1][index].answer = obj.answer
        }
      }
      // 多选
      if (value.type === '2') {
        // 判断是否存在当前题目
        const index = this.questionForm[2].findIndex(item => item.id === obj.id)
        if (index === -1) {
          this.questionForm[2].push(obj)
        } else {
          this.questionForm[2][index].answer = obj.answer
        }
      }
      // 判断
      if (value.type === '3') {
        // 判断是否存在当前题目
        const index = this.questionForm[3].findIndex(item => item.id === obj.id)
        if (index === -1) {
          this.questionForm[3].push(obj)
        } else {
          this.questionForm[3][index].answer = obj.answer
        }
      }
    },
    // 提交
    async submit() {
      const obj = {
        user_exams_id: this.user_exams_id,
        question: this.questionForm,
        usetime: this.time - this.lefttime
      }
      const { data: res } = await postMyTest(obj)
      let message = null
      if (res.status === '0') {
        message = '考试未完成'
      }
      if (res.status === '1') {
        message = '考试通过'
      }
      if (res.status === '2') {
        message = '需要补考'
      }
      if (res.status === '3') {
        message = '未通过考试'
      }
      if (res.status === '4') {
        message = '已发证书'
      }
      this.$dialog
        .alert({
          message: message
        })
        .then(() => {
          this.$router.push('/MyTest')
        })
    }
  }
}
</script>
<style lang="less" scoped>
.nav_bar .time {
  font-size: 32px;
  color: #999999;
}
.content-box {
  padding-top: 30px;
}
.bottom-button {
  width: 100%;
  position: fixed;
  bottom: 98px;
  display: flex;
  justify-content: space-around;
  .btn {
    width: 194px;
    height: 66px;
    padding: 0;
    line-height: 66px;
    background: #fed30b;
    border-radius: 30px;
    font-size: 28px;
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
