<template>
  <div class="my-collection">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="我的收藏" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <div class="wrap">
      <!-- 开通会员区域 -->
      <open-vip></open-vip>
      <!-- 开通会员区域 -->

      <!-- 课程列表区域 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <div v-for="item in list" :key="item.id">
            <course-item :classInfo="item"></course-item>
          </div>
        </van-list>
      </van-pull-refresh>
      <!-- 课程列表区域 -->
    </div>
  </div>
</template>
<script>
import OpenVip from '../../components/open-vip'
import { getUserLikeList } from '@/api/Mine/MyCollection'
import courseItem from '../../components/course-item.vue'
export default {
  name: 'MyCollection',
  components: { OpenVip, courseItem },
  data() {
    return {
      // 数据列表
      list: [],
      // 页数
      page: 0,
      // 每页条数
      limit: 10,
      // 总数
      total: 0,
      // 控制列表加载状态
      loading: false,
      // 控制是否加载完成
      finished: false,
      // 控制下拉刷新状态
      refreshing: false
    }
  },
  mounted() {},
  methods: {
    async onLoad() {
      // 下拉刷新
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
        this.page = 0
      }
      // 获取数据
      const { data } = await getUserLikeList({ page: this.page, limit: this.limit })
      this.total = data.total
      this.list = [...this.list, ...data.data]
      this.page += 1
      this.loading = false
      // 数据获取完毕
      if (this.page * this.limit >= this.total) {
        this.finished = true
      }
    },
    // 下拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.onLoad()
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 710px;
  margin: 0 auto;
}
</style>
