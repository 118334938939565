<template>
  <div class="test-box">
    <div class="test-item" v-for="item in list" :key="item.id">
      <div class="item-left">
        <img :src="item.course.course_image" alt="" v-lazy="item.course.course_image" />
      </div>
      <div class="item-right">
        <div class="title" v-text="item.plan_name"></div>
        <div class="time">课时：{{ item.course.content_duration }}</div>
        <div class="bottom">
          <div class="block" v-if="item.course.poster_status === 1">课程证书</div>
          <div class="tag">{{ item.course.study_count }}人已学习 {{ '  ' + item.course.content_count }}课</div>
        </div>
      </div>
      <van-button class="btn btn-yellow" v-if="status === 1" @click="$router.push('/TestQuestions/' + item.id)">点击考试</van-button>
      <van-button class="btn btn-green" v-else-if="status === 2">已通过</van-button>
      <van-button class="btn btn-red" v-else-if="status === 3" @click="$router.push('/TestQuestions/' + item.id)">点击补考</van-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TestItem',
  props: {
    // 数据
    list: {
      type: Array,
      required: true
    },
    // 状态
    status: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
.test-item {
  position: relative;
  height: 217px;
  width: 100%;
  padding: 30px 20px 25px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #f9f9f9;
  display: flex;
  .item-left {
    width: 160px;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .item-right {
    margin-left: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #333333;
    .title {
      font-size: 30px;
      font-weight: bold;
    }
    .time {
      font-size: 26px;
      font-weight: 400;
    }
    .bottom {
      display: flex;
      align-items: center;
      .block {
        padding: 5px;
        background: #efedf9;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
        margin-right: 22px;
      }
      .tag {
        font-size: 20px;
        font-weight: bold;
        color: #999999;
      }
    }
  }
  .btn {
    padding: 0;
    position: absolute;
    right: 0;
    bottom: 30px;
    width: 128px;
    height: 36px;
    border-radius: 16px;
    border: 0;
    font-size: 24px;
    font-weight: bold;
  }
  .btn-yellow {
    background: #fed30b;
    color: #ffffff;
  }
  .btn-green {
    background: #fff;
    color: #1eb256;
  }
  .btn-red {
    background: #dc4a3a;
    color: #fff;
  }
}
</style>
