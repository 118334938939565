<template>

  <!-- 开通会员区域 -->
  <div class="container"></div>
  <!-- 开通会员区域 -->
</template>
<script>
import { getUserInfo } from '@/api/Mine/Mine'
export default {
  name: 'Buy',
  props: ['data'],
  data() {
    return {
      // 用户信息
      userInfo: {},
      // 客服电话
      phone: null,
      // 反馈信息
      message: '',
      // 显示反馈
      show: false,
      // 考试地址
      kaoshiurl: ''
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      const { data } = await getUserInfo()
      this.kaoshiurl = 'http://employee.zfbhelper.com/index/index/?token=' + data.userinfo.token
      window.location.href = this.kaoshiurl
    }
  }
}
</script>
