<template>
  <div class="certificate-mail">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="证书邮寄" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <div class="wrap">
      <div class="certificate-info ">
        <div class="left">
          <img :src="certificateInfo.image" alt="" />
        </div>
        <div class="right">
          <div class="title">{{ certificateInfo.title }}</div>
          <span>颁发单位：{{ certificateInfo.waittext }}</span>
          <span v-if="certificateInfo.is_zige === 1">能否职业资格：能</span>
        </div>
      </div>

      <van-form>
        <van-field v-model="name" label="联系人：" :rules="[{ required: true, message: '请输入联系人' }]" />
        <van-field v-model="mobile" label="联系电话：" :rules="[{ required: true, message: '请输入联系电话' }]" />
        <van-field v-model="address" label="收货地址：" :rules="[{ required: true, message: '请输入收货地址' }]" />
      </van-form>
    </div>
    <div class="bottom">
      <div class="left">合计价格：<span class="price">20元</span></div>
      <div class="right">
        <van-button type="primary" @click="buyPoster">确认支付</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getCertificateInfo, buyPoster } from '@/api/Mine/CertificateMail'
export default {
  name: 'CertificateMail',
  props: ['id'],
  data() {
    return {
      // 证书信息
      certificateInfo: {},
      address: '',
      name: '',
      mobile: ''
    }
  },
  mounted() {
    // 获取证书详情
    this.getCertificateInfo()
  },
  methods: {
    // 获取证书详情
    async getCertificateInfo() {
      const { data: res } = await getCertificateInfo(this.id)
      this.certificateInfo = res
    },
    // 获取支付信息
    async buyPoster() {
      const obj = {
        id: this.id,
        address: this.address,
        name: this.name,
        mobile: this.mobile
      }
      const res = await buyPoster(obj)
      this.weixinPay(res.data)
    },
    // 微信支付
    async weixinPay(data) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: data.appId, // 公众号id
          timeStamp: data.timeStamp, // 当前的时间戳
          nonceStr: data.nonceStr, // 支付签名随机字符串
          package: data.package, // 订单详情扩展字符串，prepay_id=****
          signType: data.signType, // 签名类型，默认为MD5
          paySign: data.paySign // 支付签名
        },
        res => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            this.$toast.success('支付成功')
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            this.$toast.fail('支付失败')
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            this.$toast.fail('支付失败')
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  background: #f7f7f7;
  .certificate-info {
    padding: 40px 20px;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    border-bottom: 1px solid #a6a6a6;
    .left {
      width: 178px;
      height: 235px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      > .title {
        font-size: 26px;
        font-weight: bold;
        color: #666666;
        margin-bottom: 10px;
      }
      > span {
        margin-top: 19px;
        font-size: 22px;
        font-weight: 400;
        color: #999999;
      }
      .kuaidi {
        font-size: 22px;
        font-weight: 400;
        color: #333333;
        margin-top: auto;
      }
    }
  }
  /deep/ .van-form {
    padding: 70px 20px 200px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    input {
      background: #f7f7f7;
    }
  }
}
.bottom {
  width: 100%;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px -1px 1px 0px rgba(10, 70, 130, 0.4);
  position: fixed;
  bottom: 0;
  display: flex;
  .left {
    flex: 1;
    text-align: center;
    line-height: 100px;
    font-size: 30px;
    font-weight: normal;
    color: #999999;
    .price {
      color: #d11e1e;
    }
  }
  .right {
    width: 262px;
    height: 100px;
    background: #ffffff;
    box-shadow: 0px -1px 1px 0px rgba(10, 70, 130, 0.4);
    .van-button {
      background-color: #fed30b;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
