<template>
  <div class="my-test">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="我的考试" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->
    <!-- tab栏切换 -->

    <div class="wrap">
      <van-tabs v-model="active" color="#FED30B">
        <van-tab title="未考试">
          <van-list v-model="loading1" :finished="finished1" finished-text="你有一项考试待学习完成后解锁" @load="onLoad1">
            <test-item :list="list1" :status="1"></test-item>
          </van-list>
        </van-tab>
        <van-tab title="已通过">
          <van-list v-model="loading2" :finished="finished2" finished-text="没有更多了" @load="onLoad2">
            <test-item :list="list2" :status="2"></test-item>
          </van-list>
        </van-tab>
        <van-tab title="需补考">
          <van-list v-model="loading3" :finished="finished3" finished-text="没有更多了" @load="onLoad3">
            <test-item :list="list3" :status="3"></test-item>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <!-- tab栏切换 -->
  </div>
</template>
<script>
import { getMyTestList } from '@/api/Mine/MyTest'
import testItem from './components/test-item.vue'
export default {
  name: 'MyTest',
  components: { testItem },
  data() {
    return {
      active: 0,
      // 数据列表
      list1: [],
      list2: [],
      list3: [],
      // 页数
      page1: 0,
      page2: 0,
      page3: 0,
      // 每页条数
      limit: 10,
      // 总数
      total1: 0,
      total2: 0,
      total3: 0,
      // 控制列表加载状态
      loading1: false,
      loading2: false,
      loading3: false,
      // 控制是否加载完成
      finished1: false,
      finished2: false,
      finished3: false
    }
  },
  mounted() {},
  methods: {
    async onLoad1() {
      // 获取数据
      const { data } = await getMyTestList({ page: this.page1, limit: this.limit, status: 0 })
      this.total1 = data.total
      this.list1 = [...this.list1, ...data.data]
      this.page1 += 1
      this.loading1 = false
      // 数据获取完毕
      if (this.page1 * this.limit >= this.total1) {
        this.finished1 = true
      }
    },
    async onLoad2() {
      // 获取数据
      const { data } = await getMyTestList({ page: this.page2, limit: this.limit, status: 1 })
      this.total2 = data.total
      this.list2 = [...this.list2, ...data.data]
      this.page2 += 1
      this.loading2 = false
      // 数据获取完毕
      if (this.page2 * this.limit >= this.total2) {
        this.finished2 = true
      }
    },
    async onLoad3() {
      // 获取数据
      const { data } = await getMyTestList({ page: this.page3, limit: this.limit, status: 3 })
      this.total3 = data.total
      this.list3 = data.data
      this.page3 += 1
      this.loading3 = false
      // 数据获取完毕
      if (this.page3 * this.limit >= this.total3) {
        this.finished3 = true
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-tabs {
  .van-tabs__wrap {
    margin-bottom: 30px;
  }
  .van-tabs__line {
    width: 87px;
    height: 10px;
  }
}
.wrap {
  width: 710px;
  margin: 0 auto;
}
</style>
