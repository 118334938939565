<template>
  <!-- 单选 -->
  <div class="question-item" v-if="questionInfo.type === '1'">
    <div class="problem">
      <div class="tag">单选</div>
      <div class="title">{{ index + 1 + '. ' + questionInfo.question }}</div>
    </div>
    <van-radio-group v-model="radio" class="options-box">
      <van-radio v-for="(item, index) in questionInfo.selectdata" :key="index" class="radio" :name="item.key" checked-color="#2ebc89">{{ item.value }}</van-radio>
    </van-radio-group>
  </div>
  <!-- 单选 -->

  <!-- 多选 -->
  <div class="question-item" v-else-if="questionInfo.type === '2'">
    <div class="problem">
      <div class="tag">多选</div>
      <div class="title">{{ index + 1 + '. ' + questionInfo.question }}</div>
    </div>
    <van-checkbox-group v-model="select" class="options-box">
      <van-checkbox v-for="(item, index) in questionInfo.selectdata" :key="index" class="radio" :name="item.key" checked-color="#2ebc89">{{ item.value }}</van-checkbox>
      <van-checkbox class="radio" name="2" checked-color="#2ebc89">复选框 b</van-checkbox>
    </van-checkbox-group>
  </div>
  <!-- 多选 -->

  <!-- 判断 -->
  <div class="question-item" v-else-if="questionInfo.type === '3'">
    <div class="problem">
      <div class="tag">判断</div>
      <div class="title">{{ index + 1 + '. ' + questionInfo.question }}</div>
    </div>
    <van-radio-group v-model="radio" class="options-box">
      <van-radio v-for="(item, index) in questionInfo.selectdata" :key="index" class="radio" :name="item.key" checked-color="#2ebc89">{{ item.value }}</van-radio>
    </van-radio-group>
  </div>
  <!-- 判断 -->
</template>
<script>
export default {
  name: 'QuestionItem',
  props: {
    // 题目序号
    index: {
      type: Number,
      required: true
    },
    // 题目信息
    questionInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // 单选
      radio: '',
      // 多选
      select: []
    }
  },
  methods: {},
  watch: {
    radio(value) {
      const obj = {
        id: this.questionInfo.id,
        answer: value,
        type: this.questionInfo.type
      }
      this.$emit('on-change', obj)
    },
    select(value) {
      const obj = {
        id: this.questionInfo.id,
        answer: value.join(','),
        type: this.questionInfo.type
      }
      this.$emit('on-change', obj)
    }
  }
}
</script>
<style lang="less" scoped>
.question-item {
  padding: 0 57px;
  margin: 0 auto;
  .problem {
    display: flex;
    .tag {
      width: 58px;
      height: 37px;
      line-height: 37px;
      text-align: center;
      background: #2ebc89;
      border-radius: 19px 19px 0px 19px;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
    .title {
      line-height: 37px;
      flex: 1;
      margin-left: 22px;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }
  }
  .options-box {
    margin-top: 30px;
    .radio {
      margin-bottom: 40px;
      font-size: 28px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
