import request from '@/utils/request.js'
// 获取证书详情
export const getCertificateInfo = id => {
  return request({
    method: 'POST',
    url: '/api/yalimedia.user/posterdetail',
    data: {
      id
    }
  })
}
