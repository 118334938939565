<template>
  <div class="certificate-list">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="获得证书" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->

    <div class="wrap">
      <!-- 课程列表区域 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="创业指导师职业技能等级认定证书待考试通过后解锁" @load="onLoad">
          <div class="item" v-for="item in list" :key="item.id" @click="toInfo(item.id)">
            <div class="item-left">
              <img :src="item.image" alt="" v-lazy="item.image" />
            </div>
            <div class="item-right">
              <div class="title">{{ item.title | textOverflow(10) }}</div>
              <span>颁发单位：{{ item.poster.waittext }}</span>
              <span v-if="item.poster.is_zige === 1">能否职业资格：能</span>
              <div class="kuaidi" v-if="item.express_no">快递单号：{{ item.express_no }}</div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <!-- 课程列表区域 -->
    </div>
  </div>
</template>
<script>
import { getCertificateList } from '@/api/Mine/CertificateList'
export default {
  name: 'CertificateList',
  data() {
    return {
      // 数据列表
      list: [],
      // 页数
      page: 0,
      // 每页条数
      limit: 10,
      // 总数
      total: 0,
      // 控制列表加载状态
      loading: false,
      // 控制是否加载完成
      finished: false,
      // 控制下拉刷新状态
      refreshing: false
    }
  },
  mounted() {},
  methods: {
    async onLoad() {
      // 下拉刷新
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
        this.page = 0
      }
      // 获取数据
      const { data } = await getCertificateList({ page: this.page, limit: this.limit })
      this.total = data.count
      this.list = [...this.list, ...data.data]
      this.page += 1
      this.loading = false
      // 数据获取完毕
      if (this.page * this.limit >= this.total) {
        this.finished = true
      }
    },
    // 下拉刷新
    onRefresh() {
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    // 证书详情
    toInfo(id) {
      this.$router.push('/CertificateInfo/' + id)
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 630px;
  margin: 0 auto;
  padding-top: 20px;
  .item {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 40px 0;
    border-bottom: 1px solid #a6a6a6;
    .item-left {
      width: 178px;
      height: 235px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-right {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      > .title {
        font-size: 26px;
        font-weight: bold;
        color: #666666;
        margin-bottom: 10px;
      }
      > span {
        margin-top: 19px;
        font-size: 22px;
        font-weight: 400;
        color: #999999;
      }
      .kuaidi {
        font-size: 22px;
        font-weight: 400;
        color: #333333;
        margin-top: auto;
      }
    }
  }
}
</style>
