<template>
  <div class="buy-vip">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="购买会员" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->
    <div class="box">
      <div class="item" v-for="item in vipList" :key="item.id">
        <div class="top">
          <div class="title" v-text="item.name"></div>
          <div class="price">￥{{ item.price }}</div>
        </div>
        <div class="desc" v-text="item.desc"></div>
        <van-button class="button" @click="buyVip(item.id)">点击购买</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { getVipList, buyVip } from '@/api/Mine/BuyVip'
export default {
  name: 'BuyVip',
  data() {
    return {
      vipList: []
    }
  },
  mounted() {
    // 获取会员列表
    this.getVipList()
  },
  methods: {
    // 获取会员列表
    async getVipList() {
      const { data: res } = await getVipList()
      this.vipList = res.data
    },
    // 购买会员
    async buyVip(id) {
      const { data: res } = await buyVip(id)
      this.weixinPay(res)
    },
    // 微信支付
    async weixinPay(data) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: data.appId, // 公众号id
          timeStamp: data.timeStamp, // 当前的时间戳
          nonceStr: data.nonceStr, // 支付签名随机字符串
          package: data.package, // 订单详情扩展字符串，prepay_id=****
          signType: data.signType, // 签名类型，默认为MD5
          paySign: data.paySign // 支付签名
        },
        res => {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            this.$toast.success('支付成功')
          } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
            this.$toast.fail('支付失败')
          } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
            this.$toast.fail('支付失败')
          }
        }
      )
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 690px;
  padding-top: 20px;
  margin: 0 auto;
  .item {
    position: relative;
    width: 690px;
    box-sizing: border-box;
    height: 272px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-bottom: 28px;
    padding-top: 47px;
    .top {
      padding-right: 83px;
      padding-left: 51px;
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 32px;
        font-weight: 500;
        color: #fed30b;
      }
      .price {
        font-size: 28px;
        font-weight: 500;
        color: #ea2626;
      }
    }
    .desc {
      padding-left: 51px;
      margin-top: 20px;
      font-size: 28px;
      font-weight: 500;
      color: #666666;
    }
    .button {
      right: 28px;
      bottom: 32px;
      position: absolute;
      padding: 0;
      width: 160px;
      height: 52px;
      background: #fed30b;
      border-radius: 26px;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
