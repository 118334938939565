<template>
  <div class="setting">
    <!-- 导航栏区域 -->
    <van-nav-bar class="nav_bar" title="设置" placeholder fixed @click-left="$router.go(-1)">
      <template slot="left">
        <van-icon class="left_arrow" name="arrow-left" />
      </template>
    </van-nav-bar>
    <!-- 导航栏区域 -->
    <van-cell-group>
      <van-field input-align="right" v-model="userInfo.nickname" label="昵称" right-icon="arrow" placeholder="请输入昵称" readonly @click="showNickName = true" />
      <van-field input-align="right" v-model="userInfo.profession" label="职业" right-icon="arrow" placeholder="请选择职业" readonly @click="showProfession = true" />
      <van-field input-align="right" v-model="userInfo.mobile" label="手机号" right-icon="arrow" placeholder="请输入手机号" readonly @click="showMobile = true" />
      <van-field input-align="right" v-model="userInfo.wechat" label="微信号" right-icon="arrow" placeholder="请输入微信号" readonly @click="showWechat = true" />
    </van-cell-group>
    <!-- 修改昵称弹出层 -->
    <van-popup v-model="showNickName" position="bottom" :style="{ height: '130px' }">
      <div class="popup">
        <van-field v-model="editUserInfo.nickname" label="昵称" placeholder="请输入昵称" />
        <van-button type="primary" size="small" @click="resetUserInfo">修改</van-button>
      </div>
    </van-popup>
    <!-- 修改职业弹出层 -->
    <van-popup v-model="showProfession" position="bottom" :style="{ height: '200px' }">
      <van-picker title="职业" show-toolbar :columns="columns" @confirm="resetUserInfo" @change="changeProfession" />
    </van-popup>
    <!-- 修改手机号弹出层 -->
    <van-popup v-model="showMobile" position="bottom" :style="{ height: '30%' }">
      <div class="popup">
        <van-field v-model="editUserInfo.mobile" label="手机号" placeholder="请输入手机号" />
        <van-field v-model="sms" center clearable label="短信验证码" placeholder="请输入短信验证码">
          <template #button>
            <van-button size="small" type="primary" @click="sendSms">发送验证码</van-button>
          </template>
        </van-field>
        <van-button type="primary" size="small" @click="editUserMobile">修改</van-button>
      </div>
    </van-popup>
    <!-- 修改微信号弹出层 -->
    <van-popup v-model="showWechat" position="bottom" :style="{ height: '130px' }">
      <div class="popup">
        <van-field v-model="editUserInfo.wechat" label="微信号" placeholder="请输入微信号" />
        <van-button type="primary" size="small" @click="resetUserInfo">修改</van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getUserInfo, editUserInfo, sendSms, checkSms } from '@/api/Mine/Setting'
import { removeItem } from '@/utils/localStorage'
export default {
  name: 'Setting',
  data() {
    return {
      // 用户信息
      userInfo: {},
      columns: [],
      columnsId: [],
      sms: null,
      showNickName: false,
      showProfession: false,
      showMobile: false,
      showWechat: false,
      profession_list: [],
      editUserInfo: {
        nickname: null,
        profession: null,
        mobile: null,
        wechat: null
      }
    }
  },
  mounted() {
    // 获取用户信息
    this.getUserInfo()
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      const { data } = await getUserInfo()
      this.profession_list = data.userinfo.profession_list
      this.userInfo.nickname = data.userinfo.nickname
      this.userInfo.profession = data.userinfo.profession_name
      this.userInfo.mobile = data.userinfo.mobile
      this.userInfo.wechat = data.userinfo.wechat
      this.editUserInfo.nickname = data.userinfo.nickname
      this.editUserInfo.profession = data.userinfo.profession
      this.editUserInfo.mobile = data.userinfo.mobile
      this.editUserInfo.wechat = data.userinfo.wechat
      data.userinfo.profession_list.forEach(item => {
        this.columnsId = [...this.columnsId, item.id]
        this.columns = [...this.columns, item.name]
      })
    },
    // 修改用户信息
    async resetUserInfo() {
      const res = await editUserInfo(this.editUserInfo)
      if (res.code === 1) {
        this.showNickName = false
        this.showProfession = false
        this.showMobile = false
        this.showWechat = false
        this.$toast('修改成功')
        // 获取用户信息
        this.getUserInfo()
      }
    },
    // 职业改变
    changeProfession(picker, value, index) {
      this.editUserInfo.profession = this.columnsId[index]
    },
    // 发送验证码
    async sendSms() {
      const res = await sendSms(this.editUserInfo.mobile)
      if (res.code === 1) {
        this.$toast('发送成功')
      } else {
        this.$toast('发送失败')
      }
    },
    // 修改手机号
    async editUserMobile() {
      const obj = {
        mobile: this.editUserInfo.mobile,
        captcha: this.sms
      }
      const res = await checkSms(obj)
      if (res.code === 1) {
        this.resetUserInfo()
      } else {
        this.$toast('验证码错误')
      }
    },
    // 退出
    exit() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '确定要退出登录吗'
        })
        .then(() => {
          removeItem('token')
          this.$toast.success('退出成功')
          this.$router.push('/Login')
        })
        .catch(() => {
          this.$toast('已取消操作')
        })
    }
  }
}
</script>
<style lang="less" scoped>
.popup {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  .van-button {
    margin-top: 30px;
    width: 200px;
  }
}
</style>
