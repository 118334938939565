import request from '@/utils/request.js'
// 获取用户信息
export const getUserInfo = () => {
  return request({
    method: 'POST',
    url: '/api/user/index'
  })
}
// 修改用户信息
export const editUserInfo = data => {
  return request({
    method: 'POST',
    url: '/api/user/profile',
    data
  })
}
// 发送验证码
export const sendSms = mobile => {
  return request({
    method: 'POST',
    url: '/api/sms/send',
    data: {
      mobile,
      event: 'changemobile'
    }
  })
}
// 验证短信
export const checkSms = data => {
  return request({
    method: 'POST',
    url: '/api/sms/check',
    data: {
      mobile: data.mobile,
      captcha: data.captcha,
      event: 'changemobile'
    }
  })
}
